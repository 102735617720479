<template>
  <div>
    <a-modal v-model="visible" :confirmLoading="loading" :maskClosable="false" @cancel="cancel" @ok="confirm">
      <div slot="title">{{ form.id ? "编辑供应商" : "新增供应商" }}</div>
      <div>
        <a-form-model ref="form" :model="form" :rules="rules" :label-col="{ span: 12 }" :wrapper-col="{ span: 12 }">
          <a-form-model-item prop="name" label="供应商名称">
            <a-input v-model="form.name" />
          </a-form-model-item>
          <a-form-model-item prop="number" label="供应商编号">
            <a-input v-model="form.number" />
          </a-form-model-item>
          <a-form-model-item prop="contact" label="供货者">
            <a-input v-model="form.contact" />
          </a-form-model-item>
          <a-form-model-item prop="credit_code" label="统一社会信用代码/注册号/身份证号">
            <a-input v-model="form.credit_code" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period" label="有效期限">
            <a-range-picker v-model="form.valid_period" />
          </a-form-model-item>
          <a-form-model-item prop="phone" label="联系电话">
            <a-input v-model="form.phone" />
          </a-form-model-item>
          <a-form-model-item prop="address" label="联系地址">
            <a-input v-model="form.address" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image" label="证件图片">
              <a-upload
                      action="https://zxapi.vdvq.com/frontApis/shop/v1/comapi/UploadApi"
                      list-type="picture-card"
                      :file-list="form.ID_image"
                      @change="handleChange"
                      name="file">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
            </a-form-model-item>
          </div>
          <a-form-model-item prop="organization_code" label="组织机构代码">
            <a-input v-model="form.organization_code" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period1" label="有效期限">
            <a-range-picker v-model="form.valid_period1" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image1" label="证件图片">
              <a-upload
                      action="https://zxapi.vdvq.com/frontApis/shop/v1/comapi/UploadApi"
                      list-type="picture-card"
                      :file-list="form.ID_image1"
                      @change="handleChange1"
                      name="file">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
            </a-form-model-item>
          </div>
          <a-form-model-item prop="license_number" label="食品生产许可证编号">
            <a-input v-model="form.license_number" />
          </a-form-model-item>
          <a-form-model-item prop="valid_period2" label="有效期限">
            <a-range-picker v-model="form.valid_period2" />
          </a-form-model-item>
          <div style="border-bottom: 1px solid #ccc; margin-bottom: 20px">
            <a-form-model-item prop="ID_image2" label="证件图片">
              <a-upload
                      action="https://zxapi.vdvq.com/frontApis/shop/v1/comapi/UploadApi"
                      list-type="picture-card"
                      :file-list="form.ID_image2"
                      @change="handleChange2"
                      name="file">
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
            </a-form-model-item>
          </div>
          <a-form-model-item prop="email" label="邮箱">
            <a-input v-model="form.email" />
          </a-form-model-item>
          <a-form-model-item prop="bank_account" label="银行账户">
            <a-input v-model="form.bank_account" />
          </a-form-model-item>
          <a-form-model-item prop="bank_name" label="开户行">
            <a-input v-model="form.bank_name" />
          </a-form-model-item>
          <a-form-model-item prop="remark" label="备注">
            <a-input v-model="form.remark" allowClear />
          </a-form-model-item>
          <a-form-model-item prop="is_active" label="状态">
            <a-select v-model="form.is_active" style="width: 100%">
              <a-select-option value="true">激活</a-select-option>
              <a-select-option value="false">冻结</a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item prop="initial_arrears_amount" label="初期欠款金额">
            <a-input-number v-model="form.initial_arrears_amount" style="width: 100%" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { supplierCreate, supplierUpdate } from "@/api/basicData";

export default {
  name: "FormModal",
  props: ["visible", "form", "suppliersClassificationOptions"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      rules: {
        name: [
          { required: true, message: "请输入名称", trigger: "change" },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },
        ],
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        initial_arrears_amount: [
          { pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/), message: "初期欠款金额格式不正确", trigger: "change" },
        ],
      },
      loading: false,
    };
  },
  methods: {
    handleChange({ fileList }) {
      this.$set(this.form,'ID_image',fileList);
    },
    handleChange1({ fileList }) {
      this.$set(this.form,'ID_image1',fileList);
    },
    handleChange2({ fileList }) {
      this.$set(this.form,'ID_image2',fileList);
    },
    confirm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? supplierUpdate : supplierCreate,
            form = this.form,
            obj = {
              address: form.address,
              bank_account: form.bank_account,
              bank_name: form.bank_name,
              contact: form.contact,
              credit_code: {
                code: form.credit_code,
                valid_period: form.valid_period,
                ID_image: form.ID_image?.map(item=>item.response.data.file_url)
              },
              organization_code: {
                code: form.organization_code,
                valid_period: form.valid_period1,
                ID_image: form.ID_image1?.map(item=>item.response.data.file_url)
              },
              license_number: {
                code: form.license_number,
                valid_period: form.valid_period2,
                ID_image: form.ID_image2?.map(item=>item.response.data.file_url)
              },
              email: form.email,
              initial_arrears_amount: form.initial_arrears_amount,
              is_active: form.is_active,
              name: form.name,
              number: form.number,
              phone: form.phone,
              remark: form.remark
            }
          func(obj)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped></style>
